import React, { useEffect, useState } from "react"
import { observer } from "mobx-react";
import { Box, Button, CircularProgress, Divider, FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import Layout from "../../components/shared-layout";
import useStores from "../../hooks/use-stores";
import VmTable from "../../components/shared-table";
import VmButton from "../../components/shared-button";
import { ApiDataListType, ApiDataResponseListLengthCheck, CRUDOperation } from "../../constants/options";
import Breadcrumb from "../../components/shared-breadcrumb";
import VmModal from "../../components/shared-modal";
import { t } from "i18next";
import ContentLayout from "../../components/shared-content-layout";
import { INLINE_SELECTED, INLINE_SELECTED_OPTION, INLINE_SELECT_OPTION, ITEM_PERFECT_INLINED, TD_FIRST, TD_LAST, TD_NORMAL } from "../../constants/style";

const QuestionKnowledgePtIndexPage = observer(() => {
  const { rootStore, academicStore, questionStore } = useStores();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  // Determine is update or create
  const [currentSubject, setCurrentSubject] = useState<number>(0);
  const [currentQueCat, setCurrentQueCat] = useState<number>(0);

  const [targetKnowledegePt, setTargetKnowledegePt] = useState<any>();
  const [code, setCode] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [categoryId, setCategoryId] = useState<string>("0");
  const [searchSubjectKey, setSearchSubjectKey] = useState<string>("");
  const onChangeCategory = (event: SelectChangeEvent) => {
    setCategoryId(event.target.value as string);
  };

  useEffect(() => {
    if (ApiDataResponseListLengthCheck(ApiDataListType.SUBJECT) == 0 ||
    ApiDataResponseListLengthCheck(ApiDataListType.SUBJECT) != academicStore.subjectList.data.length) {
      academicStore.getSubjectList(0, 0)
        .then((res) => onToggleSubjectList(res.data[0].id));
    } else {
      onToggleSubjectList(academicStore.subjectList.data[0].id)
    }
  }, []);

  const onToggleSubjectList = (id: number) => {
    questionStore.getQuestionCategoryListBySubjectId(id, 0, 0)
      .then((res) => {
        if (res.data.length > 0) setCurrentQueCat(res.data[0].id);
      });
    setCurrentSubject(id);
  }

  const onToggleQuestionCat = (id: number) => {
    let target = questionStore.questionCatList.data.find(qc => qc.id == id);
    setCurrentQueCat(target.id);
  }

  const onActionQuestion = () => {
    let req: any = {
      id: 0,
      code,
      description,
      categoryId,
    }
    if (targetKnowledegePt) {
      req.id = targetKnowledegePt.id;
      questionStore.updateQuestionKnowledgePoint(req).then(() => {
        rootStore.notify(t('KNOWLEDEGE_POINT') + t('UPDATED_B'), 'success');
        onToggleSubjectList(currentSubject);
        handleCloseModal();
      }).catch(() => rootStore.notify(t('UPDATE_FAILED'), 'error'));
    } else {
      questionStore.createQuestionKnowledgePoint(req).then(() => {
        rootStore.notify(t('KNOWLEDEGE_POINT') + t('CREATED_B'), 'success')
        onToggleSubjectList(currentSubject);
        handleCloseModal();
      }).catch(() => rootStore.notify(t('CREATE_FAILED'), 'error'));
    }
  }

  const handleShowModal = (id?: any) => {
    if (id) {
      let target = questionStore.questionCatList.data.find(q => q.id == currentQueCat)
        .questionKnowledgePoints.find(kp => kp.id == id);
      setTargetKnowledegePt(target);
      setCode(target.code);
      setDescription(target.description);
      setCategoryId(target.categoryId);
    }
    setShowModal(true);
  }

  const handleCloseModal = () => {
    setShowModal(false);
    setShowDeleteModal(false);
    setTargetKnowledegePt(null);
    setCode("");
    setDescription("");
    setCategoryId("0");
  }

  const handleDeleteModal = (id: number) => {
    let target = questionStore.questionCatList.data.find(q => q.id == currentQueCat)
      .questionKnowledgePoints.find(kp => kp.id == id);
    setTargetKnowledegePt(target);
    setShowDeleteModal(true);
  }

  return (
    <ContentLayout
      pageName={t('KNOWLEDEGE_POINT')}
      pageHeading={t('KNOWLEDEGE_POINT')}
      breadCrumb={[
        { label: t('QUESTION') + t('SETTING_B'), to: "/question/overview" },
        { label: t('KNOWLEDEGE_POINT'), to: "/question/knowledge-point" }
      ]}
      disabledButton={currentSubject == 0 || questionStore.questionCatList.data.length == 0}
      buttonLabel={`${t('ADD_A') + t('KNOWLEDEGE_POINT')} ${questionStore.questionCatList.data.length == 0 ? `(${t('NO_QUESTION_CAT_FOUND')})` : ''}`}
      onClickAction={() => handleShowModal()}
    >
      <div className="mb-4" style={ITEM_PERFECT_INLINED}>
        <TextField
          value={searchSubjectKey}
          label={t('SEARCH_SUBJECT')}
          variant="outlined"
          sx={{ width: 300, background: 'white', marginRight: 2 }}
          onChange={(value) => setSearchSubjectKey(value.target.value)}
          size="small"
        />
        <Typography>{t('SUBJECT_COUNT')}: {academicStore.subjectList.data.filter(sub => searchSubjectKey !== "" ?
          sub.subjectCode.toLowerCase().includes(searchSubjectKey.toLowerCase()) : sub.subjectCode !== "").length}</Typography>
      </div>

      <div className="grid grid-cols-3 gap-4 my-4">
        <div className="max-h-80 overflow-auto">
          {academicStore.subjectList.data.length > 0 &&
            academicStore.subjectList.data.filter(sub => searchSubjectKey !== "" ?
              sub.subjectCode.toLowerCase().includes(searchSubjectKey.toLowerCase()) : sub.subjectCode !== "")
              .map((sub: any, index: number) => (
                <Box key={`subject_select_${index}`} sx={{ marginBottom: 1 }}>
                  <button
                    type="button"
                    className={`${currentSubject == sub.id ? INLINE_SELECTED_OPTION : INLINE_SELECT_OPTION}`}
                    onClick={() => onToggleSubjectList(sub.id)}
                  >
                    {sub.subjectCode}
                  </button>
                </Box>
              ))}
        </div>
        <div className="max-h-80 overflow-auto">
          {questionStore.questionCatList.data.length > 0 && currentQueCat != 0 ?
            questionStore.questionCatList.data.map((qc: any, index: number) => (
              <Box key={`question_categories_select_${index}`}>
                <button
                  type="button"
                  className={`${currentQueCat == qc.id ? INLINE_SELECTED_OPTION : INLINE_SELECT_OPTION}`}
                  onClick={() => onToggleQuestionCat(qc.id)}
                >
                  {qc.categoryCode}
                </button>
              </Box>
            ))
            : <Typography textAlign={"center"} className="col-span-8">{t('NO_QUESTION_CAT_FOUND')}</Typography>
          }
        </div>
      </div>

      <VmTable
        loading={academicStore.loading}
        thead={["ID", t('KNOWLEDEGE_POINT') + t('CODE_B'), t('DESCRIPTION'), t('ACTION')]}
        // if there are no question categories selected, the table data hides
        tbody={questionStore.questionCatList.data.length > 0 && currentQueCat != 0 &&
          questionStore.questionCatList.data.find(q => q.id == currentQueCat) &&
          questionStore.questionCatList.data.find(q => q.id == currentQueCat)
            .questionKnowledgePoints.map((question: any, index: number) => (
                <>
                  <Box marginY={1} />
                  <tr key={`question_${index}`}>
                  <td className={TD_FIRST}>{index + 1}</td>
                  <td className={TD_NORMAL}>{question.code}</td>
                  <td className={TD_NORMAL}>{question.description}</td>
                  <td className={TD_LAST}>
                    <Button
                      variant="outlined"
                      sx={{ marginRight: 2 }}
                      onClick={() => handleShowModal(question.id)}
                    >
                      {t('EDIT')}
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => handleDeleteModal(question.id)}
                    >
                      {t('DELETE')}
                    </Button>
                  </td>
                </tr>
                <Box marginY={1} />
              </>
            ))}
      />

      <VmModal
        openModal={showModal}
        onClose={handleCloseModal}
        title={targetKnowledegePt ? t('UPDATE_A') + t('KNOWLEDEGE_POINT') : t('ADD_A') + t('KNOWLEDEGE_POINT')}
        buttonLabel={targetKnowledegePt ? t('CONFIRM_A') + t('UPDATE') : t('CONFIRM_A') + t('CREATION')}
        buttonLabelWithoutConfirm={targetKnowledegePt ? t('UPDATE_NOW') : t('CREATE_NOW')}
        onClickConfirmedButton={onActionQuestion}
        disabledCondition={questionStore.questionCatList.length < 1 || academicStore.subjectList.length < 1 ||
          code === "" || description === ""}
      >
        <FormControl fullWidth>
          <InputLabel>{t('CATEGORY')}</InputLabel>
          {
            questionStore.questionCatList.data.length > 0 &&
            <Select
              label={t('CATEGORY')}
              sx={{ width: '100%' }}
              value={categoryId}
              onChange={onChangeCategory}
            >
              <MenuItem value={"0"}>{t('NOT_SELECTED')}</MenuItem>
              {questionStore.questionCatList.data.map((question: any, index: number) => (
                <MenuItem key={`question_category_select_${index}`} value={question.id}>
                  {question.categoryCode}
                </MenuItem>
              ))}
            </Select>
          }
        </FormControl>
        <TextField
          value={code}
          label={t('KNOWLEDEGE_POINT') + t('CODE_B')}
          variant="outlined"
          sx={{ width: '100%', marginTop: 2 }}
          onChange={(value) => setCode(value.target.value)}
        />
        <TextField
          value={description}
          type="number"
          label={t('DESCRIPTION')}
          variant="outlined"
          sx={{ width: '100%', marginTop: 2 }}
          onChange={(value) => setDescription(value.target.value)}
          multiline
          rows={4}
        />
      </VmModal>

      <VmModal
        openModal={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        title={t('DELETE_A') + t('CONFIRMATION')}
        buttonLabel={t('CONFIRM_DELETE')}
        buttonLabelWithoutConfirm={t('DELETE_NOW')}
        onClickConfirmedButton={() => {
          questionStore.deleteQuestionKnowledgePoint(targetKnowledegePt.id)
            .then(() => {
              rootStore.notify(t('KNOWLEDEGE_POINT') + t('DELETED_B'), 'success')
              onToggleSubjectList(currentSubject);
              handleCloseModal();
            }).catch(() => rootStore.notify(t('DELETE_FAILED'), 'error'))
        }}
      >
        <Typography sx={{ marginBottom: 2 }}>{t('DELETE_CHECK')}?</Typography>
        <Typography color="red" variant="caption">
          {t('DELETE_INFO_KNOWLEDGE_PT')}
        </Typography>
      </VmModal>
    </ContentLayout>
  )
});

export default QuestionKnowledgePtIndexPage;
